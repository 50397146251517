import { create } from 'zustand';
import { User } from '../types/auth';
import { 
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');

interface AuthState {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  setUser: (user: User | null) => void;
  setLoading: (loading: boolean) => void;
  logout: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  refreshToken: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isLoading: true,
  isAuthenticated: false,
  setUser: (user) => set({ 
    user, 
    isAuthenticated: !!user,
  }),
  setLoading: (loading) => set({ isLoading: loading }),
  logout: async () => {
    try {
      await signOut(auth);
      set({ user: null, isAuthenticated: false });
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  },
  signInWithGoogle: async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check if this is a new user
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      console.log('User document:', userDoc.data()); // Debug log
      
      if (!userDoc.exists()) {
        // Create a new user document
        const userData = {
          email: user.email,
          name: user.displayName,
          role: 'merchant', // Explicitly set role for new users
          createdAt: new Date().toISOString()
        };
        console.log('Creating new user with data:', userData); // Debug log
        await setDoc(doc(db, 'users', user.uid), userData);
      }
      
      // Get the latest user data and token
      const latestUserDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = latestUserDoc.data();
      const idTokenResult = await user.getIdTokenResult(true); // Force refresh
      console.log('Latest user data:', userData); // Debug log
      console.log('ID token claims:', idTokenResult.claims); // Debug log
      
      set({ 
        user: {
          ...user,
          role: idTokenResult.claims.role || userData?.role || 'merchant'
        } as User, 
        isAuthenticated: true 
      });
    } catch (error) {
      console.error('Google sign in failed:', error);
      throw error;
    }
  },
  refreshToken: async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently signed in');
      }

      // Force token refresh
      await currentUser.getIdToken(true);
      
      // Get latest token and user data
      const [idTokenResult, userDoc] = await Promise.all([
        currentUser.getIdTokenResult(),
        getDoc(doc(db, 'users', currentUser.uid))
      ]);
      
      const userData = userDoc.data();
      console.log('Latest user data after refresh:', userData);
      console.log('ID token claims after refresh:', idTokenResult.claims);
      
      // Update user in store with latest data
      set({
        user: {
          ...currentUser,
          role: idTokenResult.claims.role || userData?.role || 'merchant',
          merchantId: userData?.merchantId,
        } as User,
        isAuthenticated: true
      });

      return idTokenResult;
    } catch (error) {
      console.error('Token refresh failed:', error);
      throw error;
    }
  }
}));

// Initialize auth state listener
onAuthStateChanged(auth, async (firebaseUser) => {
  useAuthStore.getState().setLoading(true);
  
  if (firebaseUser) {
    try {
      // Get additional user data from Firestore and token claims
      const [userDoc, idTokenResult] = await Promise.all([
        getDoc(doc(db, 'users', firebaseUser.uid)),
        firebaseUser.getIdTokenResult()
      ]);
      const userData = userDoc.data();
      console.log('User data from Firestore:', userData); // Debug log
      console.log('ID token claims:', idTokenResult.claims); // Debug log
      
      // Combine Firebase user with Firestore data and token claims
      useAuthStore.getState().setUser({
        ...firebaseUser,
        role: idTokenResult.claims.role || userData?.role || 'merchant',
        merchantId: userData?.merchantId,
      } as User);
    } catch (error) {
      console.error('Error fetching user data:', error);
      useAuthStore.getState().setUser(null);
    }
  } else {
    useAuthStore.getState().setUser(null);
  }
  
  useAuthStore.getState().setLoading(false);
});